import React from 'react';
import logo from '../../assets/logo.png'
import {Link, withRouter} from "react-router-dom";
import * as routes from "../../constants/routes";

const Navigation = (props) => {
    return (
        <header className={"navigation " + (props.location.pathname === "/" ? "" : "navigation-gradient")}>
            <div className="container">
                <div className="navigation-content-wrapper">
                    <Link to={routes.LANDING}>
                        <div className="navigation-logo-wrapper">
                            <img src={logo} alt="logo" className="navigation-logo"/>
                            <h3 className="navigation-text-logo">Fisker</h3>
                        </div>
                    </Link>
                    <div className="footer-social">
                        <a href="https://www.facebook.com/FiskerApp/" target="_blank" rel="noopener noreferrer"
                           className="footer-social-item">
                            <i className="fab fa-facebook-f"/>
                        </a>
                        <a href="https://www.instagram.com/fiskerapp/" target="_blank" rel="noopener noreferrer"
                           className="footer-social-item">
                            <i className="fab fa-instagram"/>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default withRouter(Navigation)