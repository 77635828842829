import React from 'react';
import da from "../../lang/da"
import ScrollToTopOnMount from "../../services/ScrollToTopOnMount";

const Terms = () => {
    return (
        <div className="content-wrapper">
            <ScrollToTopOnMount/>
            <div className="container">
                <div className="page">
                    <h3 className="page-heading">{da.footer.links.terms}</h3>
                    <h5 className="heading">
                        1. Definitioner
                    </h5>
                    <p>"Bruger" betyder en identificerbar, fysisk person.</p>
                    <p>"Fortrolig Information" har den betydning, der er angivet i punkt 19.1.</p>
                    <p>"Ikrafttrædelsesdato" betyder den dato, hvor Ordrebekræftelsen er underskrevet af begge
                        Parter.</p>
                    <p>"Immaterielle Rettigheder" betyder alle immaterielle rettigheder af enhver art (uanset om de kan
                        registreres eller ej, og uanset om de er registrerede eller alene genstand for ansøgning om
                        registrering),
                        herunder, men ikke begrænset til, ophavsrettigheder, patentrettigheder, rettigheder til
                        opfindelser,
                        rettigheder til designs, handels- eller firmanavne, domænenavne såvel som rettigheder i forhold
                        til
                        proprietære data, teknologi og knowhow samt alle andre rettigheder eller former for beskyttelse
                        af
                        lignende
                        karakter.</p>
                    <p>"Licens" har den betydning, der er angivet i punkt 3.1.</p>
                    <p>"Part" betyder enten Fisker App eller Kunden, og "Parter" betyder Fisker App og Kunden
                        samlet.</p>
                    <p>"Vederlag" betyder vederlag i Fisker App.</p>
                    <br/>
                    <h5 className="heading">
                        2. Vilkårenes omfang
                    </h5>
                    <p>2.1. Disse brugervilkår omfatter Kundens brug af Fisker App.</p>
                    <p>2.2. Vilkårene gælder fra Kunden har hentet appen og oprettet en konto</p>
                    <p>2.3. Fisker App forbeholder sig retten til at opkræve et servicetillæg på henholdsvis DKK 4 for køb
                            under DKK 50, DKK 8 for køb mellem DKK 50 - 500, og DKK 14 for køb over DKK 500. Beløbet ved betaling
                            i anden valuta følger kurs.</p>
                    <br/>
                    <h5 className="heading">
                        3. Kundens forpligtelser
                    </h5>
                    <p>3.1. Kunden og alle Brugere må ikke:</p>
                    <ul className="remove-list">
                        <li><p>3.1.1. benytte Fisker App i strid med gældende ret;</p></li>
                        <li><p>3.1.2. overdrage kopi af Fisker App eller dele heraf, uanset form, til tredjemand uden
                            Fiskers
                            forudgående
                            skriftlige samtykke;</p>
                        </li>
                        <li><p>3.1.3. gengive materiale, der tilhører eller er del af Fisker App på hvilket sådan
                            ophavsretsangivelse
                            er
                            synlig;</p>
                        </li>
                        <li><p>3.1.4. fjerne nogen ophavsretsangivelser, varemærker, varemærkeangivelser, fortroligheds-
                            angivelse,
                            mærker, vandmærker inskription eller anden information inkluderet i Fisker App.</p>
                        </li>
                    </ul>
                    <p>3.2. Kunden er bekendt med, at brugen af Fisker App kan kræve minimums- og kompatibilitetskrav i
                        relation
                        til Brugernes hardware og software. Det er alene Kundens ansvar at sikre, at Kundens it-system
                        lever op
                        til
                        sådanne krav, som løbende kan ændres.</p>
                    <br/>
                    <h5 className="heading">
                        4. Tredjemandssoftware
                    </h5>
                    <p>4.1. Såfremt tredjemandssoftware leveres af Fisker App i henhold til vilkårene, gælder
                        tredjemands
                        standard
                        licensbetingelser for sådan tredjemandssoftware og dokumentation. Licensen for
                        tredjemandssoftwaren bliver
                        en direkte licens mellem licensgiver til tredjemandssoftwaren og Kunden, selvom Fisker App er
                        leverandør.
                        Det er Kundens ansvar at sikre overholdelse af betingelserne i en sådan licens. Fisker App
                        påtager sig
                        intet
                        ansvar for sådan tredjemandssoftware.</p>
                    <p>4.2. Medmindre andet er skriftligt aftalt mellem Parterne, vil al support til
                        tredjemandssoftwaren
                        leveres
                        direkte til Kunden af tredjemandslicensgiver, producenten eller dennes agenter.</p>
                    <br/>
                    <h5 className="heading">
                        5. Immaterielle rettigheder
                    </h5>
                    <p>5.1. Alle Immaterielle Rettigheder vedrørende Fisker App, enhver ændring eller forbedring dertil,
                        samt
                        alle
                        andre tjenester ydet af Fisker App i henhold til vilkårene, tilfalder eller forbliver hos Fisker
                        App,
                        herunder retten til forbedring, ændring, omfordeling og overførsel af sådanne Immaterielle
                        Rettigheder.</p>
                    <p>5.2. Fisker App er berettiget til at benytte alle ideer og forslag, som Kunden leverer til Fisker
                        App, fx
                        opdateringer eller udvikling af nye funktioner. Kunden giver hermed samtykke til uden vederlag
                        at
                        overdrage
                        alle Immaterielle Rettigheder til sådanne ideer og forslag til Fisker App.</p>
                    <br/>
                    <h5 className="heading">
                        6. Overholdelse af licensvilkår
                    </h5>
                    <p>6.1. Fisker App er til enhver tid, med 30 dages forudgående skriftligt varsel, og for egen
                        regning
                        berettiget til at få udarbejdet en rapport fra en udenforstående, uafhængig tredjemand, hvis
                        Fisker App
                        mistænker et brud på Kundens rettigheder i henhold til punkt 3.</p>
                    <p>6.2. Kunden skal bistå den uafhængige tredjemand i dennes inspektion ved at fremskaffe relevant
                        dokumentation.</p>
                    <p>6.3. Hvis Kundens brug af Fisker App krænker vilkårene, fx ved misbrug af licensnøgler, skal
                        Kunden
                        straks
                        dække underbetalingen baseret på de gældende Vederlag, og Kunden skal ligeledes betale alle
                        Fisker Apps
                        rimelige omkostninger vedrørende en sådan uafhængig rapport. Derudover er Fisker App berettiget
                        til at
                        kræve
                        kompensation for eventuelle yderligere tab og udgifter.</p>
                    <br/>
                    <h5 className="heading">
                        7. Garantier
                    </h5>
                    <p>7.1. Fisker App leveres til Kunden i den stand, som det er og forefindes, og Fisker App yder
                        ingen
                        direkte
                        eller indirekte garantier for, at brugen af Fisker App bliver uafbrudt og fri for mangler, eller
                        at
                        manglerne vil blive udbedret eller at Fisker App er egnet til Kundens kommercielle eller øvrige
                        behov
                        eller
                        formål.</p>
                    <p>7.2. Fisker App yder ingen garanti for, at software eller produkter leveret af eller på vegne af
                        tredjemand.</p>
                    <br/>
                    <h5 className="heading">
                        8. Ansvarsbegrænsning
                    </h5>
                    <p>8.1. Fisker App er ikke ansvarlig for Kundens tab af fortjeneste, omsætning, indtjening,
                        forventede
                        besparelser, goodwill, tab eller ødelæggelse af data, brugs- eller værditab på data eller
                        udstyr, eller
                        andet direkte eller indirekte tab i forbindelse med, men ikke begrænset til, mangler,
                        forsinkelser eller
                        afbrydelser.</p>
                    <p>8.2. Fisker App understreger udtrykkeligt, at Fisker App kun er et støtteværktøj beregnet til at
                        hjælpe
                        Kunden i dennes bestræbelser på at tilegne sig relevante services i appen.</p>
                    <br/>
                    <h5 className="heading">
                        9. Skadesløsholdelse
                    </h5>
                    <p>9.1. Kunden skal skadesløsholde Fisker App for bøder, sanktioner, tilkendt erstatning eller
                        forligsbeløb,
                        og alle andre dokumenterede omkostninger, som Fisker App har afholdt eller er blevet tilkendt i
                        forbindelse med krav fra tredjemand vedrørende (i) krænkelse af tredjemands Immaterielle
                        Rettigheder til
                        hardware, software og andet udstyr leveret af eller via Kunden i henhold til denne Aftale, eller
                        (ii) brud
                        på myndighedskrav for hvilke Kunden er ansvarlig. </p>
                    <br/>
                    <h5 className="heading">
                        10. Ændringer
                    </h5>
                    <p>10.1. Fisker App forbeholder sig retten til at ændre disse vilkår med tredive (30) dages
                        skriftligt
                        varsel til Kunden. Kunden skal orienteres herom via den email, der er angivet ved
                        oprettelse.</p>
                    <br/>
                    <h5 className="heading">
                        11. Force Majeure
                    </h5>
                    <p>11.1. Såfremt udefrakommende omstændigheder af ekstraordinær karakter, der opstår uden en Parts
                        skyld, og
                        som Parten ikke har kontrol over, medfører, at opfyldelse af vilkårene er umulig eller vil
                        påføre Parten
                        en urimelig byrde, fritages den pågældende Part for opfyldelse af vilkårene, indtil de
                        pågældende
                        omstændigheder kan overvindes.</p>
                    <br/>
                    <h5 className="heading">
                        12. Overdragelse
                    </h5>
                    <p>12.1. Kunden er ikke berettigede til helt eller delvist at overdrage sine rettigheder og
                        forpligtelser i
                        henhold til disse vilkår til tredjemand, uden Fisker Apps skriftlige samtykke.</p>
                    <p>12.2. Fisker App er berettiget til helt eller delvist at overdrage sine rettigheder og
                        forpligtelser i
                        henhold til disse vilkår til tredjemand, uden Kundens skriftlige samtykke.</p>
                    <br/>
                    <h5 className="heading">
                        13. Hemmeligholdelse
                    </h5>
                    <p>13.1. Ingen af Parterne er berettigede til, medmindre det er nødvendigt i henhold til gældende
                        lovgivning
                        eller endelig afgørelse fra retslig eller anden kompetent instans, at (i) benytte, medmindre det
                        er til
                        formål omfattet af denne Aftale, (ii) videregive til tredjemand eller (iii) offentliggøre nogen
                        Fortrolig
                        Information modtaget fra den anden Part i henhold til eller i forbindelse med denne Aftale. I
                        denne Aftale
                        betyder "Fortrolig Information" en Parts forretningsmæssige og driftsmæssige information og
                        knowhow og
                        enhver anden information, som ikke er almindelig kendt eller tilgængelig for offentligheden.</p>
                    <p>13.2. Parterne skal sikre, at deres medarbejdere også overholder dette punkt 19.</p>
                    <p>13.3. Bestemmelserne i dette punkt 19 skal gælde uden tidsbegrænsning og skal fortsat være gyldig
                        efter
                        enhver ophævelse uanset grund.</p>
                    <br/>
                    <h5 className="heading">
                        14. Værneting
                    </h5>
                    <p>14.1. Denne Aftale er underlagt dansk ret, idet der dog skal ses bort fra danske internationale
                        privatretlige regler.</p>
                    <p>14.2. Enhver tvist, som måtte opstå i forbindelse med denne Aftale, herunder enhver tvist
                        vedrørende
                        Fisker
                        App, vilkårenes gyldighed eller ophævelse, og som ikke kan løses i mindelighed mellem Parterne,
                        skal
                        indbringes for Københavns Byret.</p>
                </div>
            </div>
        </div>
    )
};


export default Terms