import React, {useEffect, useState} from "react";
import Card from "./Card";


const Carousel = () => {

    const [lakes, setLakes] = useState([]);

    useEffect(() => {

        fetch("https://web-api.fiskerapp.com/services", {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",

            headers: {
                "Authorization": "deezfiskernutz"
            },
            referrerPolicy: "no-referrer"
        })
            .then(async (response) => {
                const res = await response.json();
                return setLakes(res);
            })
            .catch(err => console.log(err));

    }, []);

    return (
        <div className="card-wrapper">
            {lakes.map((lake, index) => {
                return Card(lake, index)
            })}
        </div>
    );
};

export default Carousel