import React from 'react';

const Card = (lake, index) => {
    const handleClick = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    };
    return (
        <div key={index} className="card-block" onClick={(e) => handleClick(e, lake.website)}>
            <a href={lake.website} target="_blank" rel="noopener noreferrer"
               className="card" style={{backgroundImage: "url(" + lake.image + ")"}}>
                <p>{lake.name}</p>
            </a>
        </div>
    );
};

export default Card