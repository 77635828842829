import React from 'react';
import ScrollToTopOnMount from "../../services/ScrollToTopOnMount";

const Privacy = () => {
    return (
        <div className="content-wrapper">
            <ScrollToTopOnMount/>
            <div className="container">
                <div className="page">
                    <h3 className="page-heading">Privacy Policy</h3>
                    <h5 className="heading">
                        1. Data controller
                    </h5>
                    <p>The entity responsible for the processing of your personal information is:</p>
                    <p>FiskerApp IVS</p>
                    <p>Randersvej 73, 2. Th.</p>
                    <p>8200 Aarhus N</p>
                    <p>CVR: 39826321</p>
                    <p><a href="mailto:kenneth@fiskerapp.com"
                          className="footer-link-underline">kenneth@fiskerapp.com</a></p>
                    <p><a href="tel:+ 45 27 58 58 41" className="footer-link-underline">+ 45 27 58 58 41</a></p>
                    <br/>
                    <h5 className="heading">
                        2. Description of the processing
                    </h5>
                    <div className="table-responsive table-responsive-sm">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th className="heading">Purpose</th>
                                <th className="heading">Categories of personal data</th>
                                <th className="heading">Source of the personal data</th>
                                <th className="heading">Legal basis for the processing</th>
                                <th className="heading">Recipients</th>
                                <th className="heading">Data retention</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Provision of our services to users:
                                    Personal data is used in order to contact you concerning the app and other services
                                    that can be
                                    ordered through the app.
                                </td>
                                <td>We process the following categories of personal data about you:
                                    a) Ordinary personal data:
                                    <ul>
                                        <li>Name, email address</li>
                                        <li>Username and password</li>
                                        <li>Purchase history, use of our digital services,</li>
                                        <li>Location data</li>
                                        <li>Picture</li>
                                    </ul>
                                </td>
                                <td>We collect your personal data from the following source(s):
                                    <ul>
                                        <li>Directly from you</li>
                                        <li>A previous employer</li>
                                        <li>Online sources, e.g. social media that are publicly available</li>
                                        <li>Public authorities</li>
                                    </ul>
                                </td>
                                <td>We process your personal data on the following legal bases:
                                    <ul>
                                        <li>
                                            Article 6.1.b (necessary for the purpose of
                                        </li>
                                        <li>
                                            Article 6.1.f (necessary for the pursuit of legitimate purposes of us) It is
                                            necessary for
                                            FiskerApp IVS to provide the service to you.
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    We share your personal data with:
                                    <ul>
                                        <li>Suppliers and vendors that We work with to assist our company (meaning
                                            service providers,
                                            technical support, supply services, and financial institutions)
                                        </li>
                                        <li>
                                            Public authorities
                                        </li>
                                    </ul>

                                </td>
                                <td>We will retain personal data processed for this purpose for:
                                    <ul>
                                        <li>
                                            Up to 5 years from the last purchase through the app, cf. the Danish
                                            Bookkeeping Act.
                                        </li>
                                        <li>
                                            Location data is retained while the app is in use.
                                            Criteria may be dictated by factors such as statutory requirements or
                                            industry guidelines.
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                            <tbody>
                            <td>
                                Newsletter:
                                <br/>
                                Personal data is used for marketing-related purposes, including to target our
                                communication with you
                                in view of your areas of interests and focus and to send you relevant product promotion
                                in the form of
                                newsletters.
                            </td>
                            <td>We process the following categories of personal data about you:
                                a) Ordinary personal data:
                                <ul>
                                    <li>Email address</li>
                                </ul>
                            </td>
                            <td>
                                We collect your personal data from the following source(s):
                                <ul>
                                    <li>
                                        Directly from you
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We process your personal data on the following legal bases:
                                <ul>
                                    <li>
                                        Article 6.1.f (necessary for the pursuit of legitimate purposes of us in order
                                        to provide
                                        marketing material)
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We share your personal data with:
                                <ul>
                                    <li>Suppliers and vendors that We work with to assist our company (meaning service
                                        providers,
                                        technical support, supply services, and financial institutions)
                                    </li>
                                    <li>
                                        Public authorities
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We will retain personal data processed for this purpose for:
                                <ul>
                                    <li>
                                        Up to 1 year from our last communication with you.
                                    </li>
                                </ul>
                                Criteria may be dictated by factors such as statutory requirements or industry
                                guidelines.
                            </td>
                            </tbody>
                            <tbody>
                            <td>
                                Business- and product development, i.e. statistics for the use of app and optimization
                                of user
                                experience on app.
                            </td>
                            <td>
                                We process the following categories of personal data about you:
                                a) Ordinary personal data:
                                <ul>
                                    <li>Name, address, email address, telephone number</li>
                                    <li>Purchase history, use of our digital services</li>
                                    <li>Location data</li>
                                    <li>Picture</li>
                                </ul>
                            </td>
                            <td>
                                We collect your personal data from the following source(s):
                                <ul>
                                    <li>
                                        Directly from you
                                    </li>
                                    <li>
                                        A previous employer
                                    </li>
                                    <li>
                                        Online sources, e.g. social media that are publicly available
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We process your personal data on the following legal bases:
                                <ul>
                                    <li>
                                        Article 6.1.f (necessary for the pursuit of legitimate purposes of us). It is
                                        FiskerApp IVS'
                                        legitimate interest to optimize and improve the app.
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We share your personal data with:
                                <ul>
                                    <li>
                                        Suppliers and vendors that We work with to assist our company (meaning service
                                        providers,
                                        technical support, supply services, and financial institutions)
                                    </li>
                                    <li>
                                        Public authorities
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We will retain personal data processed for this purpose for:
                                <ul>
                                    <li>
                                        Up to 1 year from the use of the app.
                                    </li>
                                </ul>
                                Criteria may be dictated by factors such as statutory requirements or industry
                                guidelines.
                            </td>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <h5 className="heading">
                        3. Transfers to countries outside the EU/EEA
                    </h5>
                    <p>We do not transfer personal data to countries outside the EU/EEA. </p>
                    <br/>
                    <h5 className="heading">
                        4. Your rights
                    </h5>
                    <p> You have the following rights:</p>
                    <ul>
                        <li><p>You have the right to request access to, rectification or erasure of your personal
                            data.</p></li>
                        <li><p>You also have the right to have the processing of your personal data restricted.</p></li>
                        <li><p>If processing of your personal information is based on your consent, you have the right
                            to withdraw
                            your
                            consent at any time. Your withdrawal will not affect the lawfulness of the processing
                            carried out before
                            you
                            withdrew your consent. You may withdraw your consent by contacting us on
                            kenneth@fiskerapp.com.</p></li>
                        <li><p>You have the right to receive your personal information in a structured, commonly used
                            and
                            machine-readable format (data portability).</p></li>
                        <li><p>You may always lodge a complaint with a data protection supervisory authority, e.g. The
                            Danish
                            Data
                            Protection Agency.</p></li>
                    </ul>
                    <p>Furthermore, you have the right to object to processing of your personal data as follows. </p>
                    <ul>
                        <li>
                            <p>
                                If processing of your personal data is based on article 6.1.e or article 6.1.f, see
                                above regarding
                                legal basis, you have the right to object, on grounds relating to your particular
                                situation, at any
                                time
                                to the processing of your personal data.</p>
                        </li>
                        <li>
                            <p>Where your personal data are processed for direct marketing purposes, you have the right
                                to object at
                                any time to the processing of personal data about you for such marketing.</p>
                        </li>
                    </ul>
                    <p>
                        You can take steps to exercise your rights by contacting us on kenneth@fiskerapp.com with your
                        request.
                        There may be conditions or limitations on these rights.
                    </p>
                    <p>
                        It is therefore not certain for example you have
                        the right of data portability in the specific case - this depends on the specific circumstances
                        of the
                        processing activity.
                    </p>
                    <br/>
                    <p><i>Last updated: 21-02-2019</i></p>
                </div>

            </div>
        </div>
    )
};

export default Privacy