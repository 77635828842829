import React from 'react';
import {Link} from "react-router-dom";
import * as routes from "../../constants/routes";
import da from "../../lang/da"

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="footer-nav">
                    <a href="mailto:info@fiskerapp.dk" target="_blank" rel="noopener noreferrer"
                       className="footer-link footer-link-underline">info@fiskerapp.dk</a>
                    <span
                        className="footer-copyright footer-copyright-desktop">© {new Date().getFullYear()} FiskerApp IVS</span>
                    <div className="footer-routes">
                        <Link to={routes.PRIVACY} className="footer-link">
                            {da.footer.links.privacy}
                        </Link>
                        <Link to={routes.TERMS} className="footer-link">
                            {da.footer.links.terms}
                        </Link>
                    </div>
                </div>
                <span
                    className="footer-copyright footer-copyright-mobil">© {new Date().getFullYear()} FiskerApp IVS</span>
            </div>
        </footer>
    )
};

export default Footer