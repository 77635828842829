import React from 'react';
import heroMock from '../assets/iphone.png'
import itemMock1 from '../assets/iphones.png'
import itemMock2 from '../assets/put_take.png'
import da from "../lang/da"
import ScrollToTopOnMount from "../services/ScrollToTopOnMount";
import Carousel from "./cards/Carousel";

const Landing = () => {

    return (
        <div className="content-wrapper">
            <ScrollToTopOnMount/>
            <div className="hero">
                <div className="hero-gradient"/>
                <div className="hero-banner">
                    <div className="container">
                        <h1 className="hero-heading">
                            {da.landing.heroTitle}
                        </h1>
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="hero-text">
                                    {da.landing.heroText}
                                </p>
                                <br/>
                                <div className="hero-cta">
                                    <a href="https://itunes.apple.com/dk/app/fisker/id1436963722" target="_blank"
                                       rel="noopener noreferrer" className="hero-button hero-button-row">
                                        <span>{da.landing.heroButtonApple}</span>
                                        <i className="fab fa-apple"/>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.fiskerapp.fisker"
                                       rel="noopener noreferrer" className="hero-button hero-button-row">
                                        <span>{da.landing.heroButtonAndroid}</span>
                                        <i className="fab fa-android"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <img src={heroMock} alt="mockup" className="hero-mock"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <h2 className="heading heading-center">{da.landing.howItWorks}</h2>
                        <p className="heading-center">Udforsk, hvordan app'en fungerer</p>
                        <br/>
                        <div className="item item-wrap-reverse">
                            <div className="item-right">
                                <h4 className="heading">
                                    {da.landing.overviewTitle}
                                </h4>
                                <p>
                                    {da.landing.overviewText}
                                </p>
                            </div>
                            <img src={itemMock1} alt="mockup" className="item-mock"/>
                        </div>
                        <div className="item item-wrap">
                            <img src={itemMock2} alt="mockup" className="item-mock"/>
                            <div className="item-left">
                                <h4 className="heading">
                                    {da.landing.paymentTitle}
                                </h4>
                                <p>
                                    {da.landing.paymentText}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dark-bg content">
                <div className="container">
                    <h2 className="heading heading-center">Her kan du benytte Fisker</h2>
                    <Carousel/>
                </div>
            </div>
            <div className="cta">
                <h4 className="heading-white">
                    {da.landing.cta}
                </h4>
                <div className="hero-cta">
                    <a href="https://itunes.apple.com/dk/app/fisker/id1436963722" target="_blank"
                       rel="noopener noreferrer" className="hero-button hero-button-row">
                        <span>{da.landing.heroButtonApple}</span>
                        <i className="fab fa-apple"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.fiskerapp.fisker" target="_blank"
                       rel="noopener noreferrer" className="hero-button">
                        <span>{da.landing.heroButtonAndroid}</span>
                        <i className="fab fa-android"/>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Landing
