import React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import * as routes from "./constants/routes";
import Navigation from "./components/partials/Navigation";
import Landing from "./components/Landing";
import NoMatch from "./components/NoMatch";
import Footer from "./components/partials/Footer";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import "./services/GaService"

const App = () => {

    return (
        <Router>
            <Navigation/>
            <Switch>
                <Route exact path={routes.LANDING} component={() => <Landing/>}/>
                <Route path={routes.PRIVACY} component={() => <Privacy/>}/>
                <Route path={routes.TERMS} component={() => <Terms/>}/>
                <Route component={() => <NoMatch/>}/>
            </Switch>
            <Footer/>
        </Router>
    );
};

export default App;
