import React from 'react';
import * as routes from '../constants/routes';
import {Link} from "react-router-dom";

const NoMatch = () => {
    return (
        <div className="content-wrapper">
            <div className="error-page">
                <div>
                    <h2 className="heading">404 - Ingen fangst</h2>
                    <Link to={routes.LANDING} className="error-back">
                        <i className="fas fa-long-arrow-alt-left"/>
                        Tilbage
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default NoMatch